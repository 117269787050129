import React from 'react';
import { graphql } from 'gatsby';

import ArticleComponent from '../components/articles/article/Article';
import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import PageModel from '../models/Page';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query {
    allSanityPage(filter: { uid: { current: { eq: "catalogs" } } }) {
      edges {
        node {
          _id
          featuredMedia {
            image {
              asset {
                gatsbyImageData
              }
            }
            type
            video {
              imageUrl
              value
            }
            videoPickit {
              imageUrl
              value
            }
            videoType
            videoYoutube
          }
          ingress {
            languagePrimary
            languageSecondary
          }
          site {
            brand {
              uid {
                current
              }
            }
            uid {
              current
            }
          }
          slices {
            ... on SanityPublicationSlice {
              ...PublicationSlice
            }
          }
          title {
            languagePrimary
            languageSecondary
          }
          uid {
            current
          }
        }
      }
    }
  }
`;

const Catalogs = (props) => {
  const { brand, data, site, path } = props;
  const catalogPages = mapEdgesToNodes(data.allSanityPage);
  const catalogPage = catalogPages.find(
    (page) =>
      page.site.brand?.uid?.current === brand.brandUID &&
      page.site.uid.current === site.siteUID
  );

  if (!catalogPage) return null;

  const article = new PageModel(catalogPage, site.siteUID);

  return (
    <ArticleComponent
      article={article}
      breadcrumbs={breadcrumbs('CATALOGS', article)}
      jsonLd={jsonLd(brand, site, path, article)}
      site={site}
    />
  );
};

export default Catalogs;
